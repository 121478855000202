import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './CookieConsent.scss';
import { Box, Button, Fade, Paper, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import TrapFocus from '@mui/material/Unstable_TrapFocus';

function CookieConsent({
  cookieName,
  debug,
  cookieValue,
  privacyPolicyURL,
  expires,
  onAccept,
  extraCookieOptions,
  message,
}) {
  const [bannerOpen, setBannerOpen] = useState(true);

  /**
   * Set a persistent cookie
   */
  const handleAccept = () => {
    if (onAccept) {
      onAccept();
    }
    Cookies.set(cookieName, cookieValue, { expires, ...extraCookieOptions });

    setBannerOpen(false);
  };

  useEffect(() => {
    if (Cookies.get(cookieName) === undefined || debug) {
      setBannerOpen(true);
    }else{
        setBannerOpen(false);
        }
  }, [cookieName, debug]);

  return (
    <TrapFocus open disableAutoFocus disableEnforceFocus>
      <Fade appear={false} in={bannerOpen}>
        <Paper
          role="dialog"
          aria-modal="false"
          aria-label="Cookie banner"
          square
          variant="outlined"
          tabIndex={-1}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            m: 0,
            p: 2,
            borderWidth: 0,
            borderTopWidth: 1,
          }}
        >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ justifyContent: 'space-between', gap: 2 }}
          >
            <Box sx={{ flexShrink: 1, alignSelf: { xs: 'flex-start', sm: 'center' } }}>
              <Typography sx={{ fontWeight: 'bold' }}>This website uses cookies</Typography>
              <Typography variant="body2">{message}
              <Link className="link" to={{ pathname: privacyPolicyURL }} target="_blank" >
                Find out how we use cookies.
              </Link>
              </Typography>
              
            </Box>
            <Stack
              direction={{
                xs: 'row-reverse',
                sm: 'row',
              }}
              sx={{
                gap: 2,
                flexShrink: 0,
                alignSelf: { xs: 'flex-end', sm: 'center' },
              }}
            >
              <Button size="small" onClick={handleAccept} variant="contained">
                Accept
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Fade>
    </TrapFocus>
  );
}

CookieConsent.defaultProps = {
    privacyPolicyURL: '',
    cookieValue: true,
    expires: 365,
    message: '',
  };

export default CookieConsent;
