import React, { useState, useEffect, useContext } from 'react';
import { MaterialReactTable } from 'material-react-table'; // Import as a named export
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import { columns } from './SavedFilesTable.utils';
import { StoreContext } from '../../store/storeContext';
import DeleteSavedQueryModal from './deleteSavedFileModal';

import {
  getUsersScheduledQueriesReportFilesActiveByUser,
  getUserScheduledQueryReportFile,
  disableUserScheduledQueryReportFile,
} from '../../services/usersScheduledQueries.api';
import './SavedFilesTable.css'; // Import custom CSS

function SavedFilesTable() {
  const [isLoading, setIsLoading] = useState(true);
  const [
    availableUsersScheduledQueriesReportFilesActive,
    setAvailableUsersScheduledQueriesReportFilesActive,
  ] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const deleteMessage = `Delete ${currentRow.reportUiFileName}?`;
  const [refreshTable, setRefreshTable] = useState(false);

  const [reportSelectDictionary, setReportSelectDictionary] = useState([]);
  const [reportSelectArray, setReportSelectArray] = useState([]);
  const { actions: actionsContext } = useContext(StoreContext);

  function getReportFiles() {
    getUsersScheduledQueriesReportFilesActiveByUser()
      .then((availableUserScheduledQueriesReportFilesData) => {
        setAvailableUsersScheduledQueriesReportFilesActive(
          availableUserScheduledQueriesReportFilesData,
        );
        setIsLoading(false);
      })
      .catch((error) => {
        actionsContext.errorAction(error);
      });
  }

  useEffect(() => {
    getReportFiles();
  }, []);

  function handleDisableReportFileButtonClick(rowData) {
    if (rowData.userScheduledQueryReportFileId) {
      disableUserScheduledQueryReportFile(rowData?.userScheduledQueryReportFileId)
        .then(() => {
          getReportFiles();
        })
        .catch((error) => {
          actionsContext.errorAction(error);
        });
    }
  }

  function downloadFile(id, name) {
    getUserScheduledQueryReportFile(id)
      .then((res) => {
        res.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = name;
          alink.click();
        });
      })
      .catch((error) => {
        actionsContext.errorAction(error);
      });
  }

  useEffect(() => {
    setRefreshTable(false);
  }, [refreshTable]);

  return (
    <div>
      <h6><i>Files are removed after 45 days</i></h6>
      <MaterialReactTable
        columns={columns(
          reportSelectArray,
          reportSelectDictionary,
          setReportSelectArray,
          setReportSelectDictionary,
        )}
        data={availableUsersScheduledQueriesReportFilesActive}
        state={{ isLoading }}
        initialState={{ pagination: { pageSize: 10 } }}
        enableColumnFilters
        enableRowActions
        muiTableBodyCellProps={{
          style: { fontSize: '16px' }, // Adjust the font size for table cells
        }}
        muiTableBodyRowProps={{
          style: { height: '50px' }, // Adjust the font size and row height
        }}
        renderRowActions={({ row }) => (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Tooltip title="Download">
              <button
                onClick={() => downloadFile(row.original.userScheduledQueryReportFileId, row.original.reportUiFileName)}
                className="action-button"
                type="button" // Add type attribute
                aria-label="Download" // Add aria-label for accessibility
              >
                <DownloadIcon className="action-icon" />
              </button>
            </Tooltip>
            <Tooltip title="Delete">
              <button
                onClick={() => { setShowModal(true); setCurrentRow(row.original); }}
                className="action-button"
                type="button" // Add type attribute
                aria-label="Delete" // Add aria-label for accessibility
              >
                <DeleteIcon className="action-icon" />
              </button>
            </Tooltip>
          </div>
        )}
      />
      <DeleteSavedQueryModal
        title="Delete saved file"
        text={deleteMessage}
        show={showModal}
        onHideModal={() => {
          setShowModal(false);
        }}
        onConfirmAction={() => {
          handleDisableReportFileButtonClick(currentRow);
          setShowModal(false);
        }}
      />
    </div>
  );
}

export default SavedFilesTable;