/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { UsersScheduledQueriesFormatTypes } from '../../utils/constants/enums';

function getUserQueryReportsArrayObject(reportIds, reportNms) {
  const reportIdsArray = reportIds?.split(',');
  const reportNamesArray = reportNms?.split(',');
  if (reportIdsArray == null) {
    return [];
  }
  return reportIdsArray?.map((id, index) => ({
    reportId: id,
    reportNm: reportNamesArray[index],
  }));
}

function getKeyByValue(object, value) {
  const response = Object.keys(object).find((key) => object[key] === parseInt(value));
  return response;
}

const getReportSelectValue = (userQueryOptionId, reportSelectDictionary) =>
  reportSelectDictionary?.find((item) => item.key === userQueryOptionId)?.value;

function CellComponent({ cell, format }) {
  return (
    <span className="query-name" style={{ whiteSpace: 'pre' }}>
      {format ? moment(cell.getValue(), format).format(format) : cell.getValue()}
    </span>
  );
}

CellComponent.propTypes = {
  cell: PropTypes.shape({
    getValue: PropTypes.func.isRequired,
  }).isRequired,
  format: PropTypes.string,
};

CellComponent.defaultProps = {
  format: null,
};

function FormatCellComponent({ cell }) {
  return (
    <span className="query-name">
      {getKeyByValue(UsersScheduledQueriesFormatTypes, cell.getValue())}
    </span>
  );
}

FormatCellComponent.propTypes = {
  cell: PropTypes.shape({
    getValue: PropTypes.func.isRequired,
  }).isRequired,
};

export const REPORT_AREA = ['Market', 'Risk'];

const columns = () => [
  {
    accessorKey: 'createTs',
    header: 'Date Created',
    Cell: (props) => <CellComponent {...props} format="YYYY-MM-DD hh:mm:ss" />,
  },
  {
    accessorKey: 'reportUiFileName',
    header: 'File Name',
    Cell: CellComponent,
  },
  {
    accessorKey: 'reportArea',
    header: 'Report Area',
    Cell: CellComponent,
    filterVariant: 'multi-select',
    filterSelectOptions: REPORT_AREA,
    filterFn: (row, id, filterValue) => filterValue.every((value) => row.getValue(id).includes(value)),
  },
  {
    accessorKey: 'reportType',
    header: 'Report Type',
    Cell: CellComponent,
  },
  {
    accessorKey: 'userScheduledQueryFormatTypeId',
    header: 'Format',
    enableColumnFilter: false,
    Cell: FormatCellComponent,
  },
];

export { columns, getReportSelectValue, getUserQueryReportsArrayObject };