import React from 'react';
import Grid from '@mui/material/Grid';
import './LoginPageLayout.scss';


function LoginPageLayout({ children }) {
  return (
    <Grid container>
      <Grid className="loginPanel" item md={4} xs={12} container direction="column">
        {children}
      </Grid>
      <Grid className="content" item md={8} xs={12}>
        <img
          alt="landingPageImage"
          className="landingPageImage"
          src="./images/LandingPageImage.jpg"
        />
      </Grid>

    </Grid>
  );
}
export default LoginPageLayout;
