import React, { useState, useContext, createContext, useEffect } from 'react';

const TableauQueryContext = createContext();
TableauQueryContext.displayName = 'TableauQueryContext';

function TableauQueryProvider({ children, ...props }) {
  const value = useState(JSON.parse(window.localStorage.getItem('reportDisplay')));

  useEffect(() => {
    const [state] = value;
    window.localStorage.setItem('reportDisplay', JSON.stringify(state));
  }, [value]);

  return (
    <TableauQueryContext.Provider value={value} {...props}>
      {children}
    </TableauQueryContext.Provider>
  );
}

const useTableauQuery = () => {
  const context = useContext(TableauQueryContext);
  if (!context) throw new Error(`useTableauQuery must be used within the TableauQueryProvider`);

  return context;
};

// Commented fields are optional
const queryModel = {
  // queryId: -1,
  queryType: 1,
  // queryTypeDescription: '',
  // createTs: '',
  // updateTs: '',
  // createdBy: '',
  // updatedBy: '',
  savedQueryId: '',
  queryName: '',
  queryDescription: '',
  // queryDescription: '',
  riskLayoutCriteriaSelection: {
    riskDisplayBy: {},
    riskGroupBy: {},
  },
  marketLayoutCriteriaSelection: {
    marketDisplayBy: {},
    marketGroupBy: {},
    marketRollBy: {},
  },
  territorySelection: {
    territoryTypes: { 1: 1 },
    states: {},
    // dmas: {},
    // counties: {},
    // zips: {},
  },
  indicatorSelection: {
    // lenderTypes: {1: {lenderTypeDescription: "Banks", lenderTypeId: 1},2: {lenderTypeDescription: "Buy Here/Pay Here", lenderTypeId: 2}},
    //   dealerTypes: {},
    //   transactionsTypes: {},
  }, // dealersAndLenders: {
  //   dealers: {},
  //   lenders: {},
  // },
  vehiclesSelection: {
    // bodyTypes: DEFAULT_BODY_TYPES,
    // manufacturers: {},
    // makes: {},
    // models: {},
    // segments: {},
    // modelYears: {},
  },

  //  chosenReports: {},
  //  marketOptions: {},
  //  distributionOptions: {},
  //  reportingPeriods: {},
  //  riskOptionsSelections: {
  //    userQueryOptionId: 0,
  //    editableRiskOption: {
  //      scoreType: {
  //      },
  //      customScoreRangeOption: false,
  //      customScoreType: {
  //      },
  //      customTiersOption: false,
  //      customTiers: [
  //      ],
  //    },
  // },
  dealersAndLendersSelection: {},
  chosenReports: {},
  reportingPeriods: {},
};

export { TableauQueryProvider, useTableauQuery, queryModel };
