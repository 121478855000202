/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import {
  routes,
  routesWithMarketTab,
  routesWithMarketAndtRiskTabs,
  LAYOUT,
} from '../../utils/constants/autocount.routes';
import { renderRoutes } from '../../utils/router.utils';
import Navbar from '../../components/navigation/Navbar';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import './AutocountLayout.scss';
import { getDataAccessClientsReportTypesActive } from '../../services/dataAccess.api';
import { useAsync } from '../../utils/useAsync';
import CookieConsent from '../../components/cookieConsent/CookieConsent';
import { getPersonaDisplay } from '../../services/persona.api';
import { errorStaticMessages } from '../../utils/constants/staticMessages';
import { useLocalStorage } from '../../utils/useLocalStorage';



function AutocountLayout(props) {
  const { run: runGetDataAccessClientsReportTypesActive } = useAsync();
  const [dataAccessClientsReportTypesActive, setDataAccessClientsReportTypesActive] = useState(0);
  const [personaUser, setPersonaUser] = useState();
  const [personaStatus, setPersonaStatus] = useLocalStorage('personaStatus', false);



  useEffect(() => {
    getPersonaDisplay()
      .then((data) => {
        if (data.userId) {
          setPersonaUser(data);
        } else {
          setPersonaStatus(false);
        }
      })
      .catch((error) => {
        console.error(`getPersonaDisplay api. Error: ${error}`);
        return errorStaticMessages.msgServerConnectionFailed;
      });

    runGetDataAccessClientsReportTypesActive(
      getDataAccessClientsReportTypesActive().then((getDataAccessClientsReportTypesActiveData) => {
        if (
          getDataAccessClientsReportTypesActiveData !== undefined &&
          getDataAccessClientsReportTypesActiveData.length > 0
        ) {
          setDataAccessClientsReportTypesActive(
            getDataAccessClientsReportTypesActiveData[
              getDataAccessClientsReportTypesActiveData.length - 1
            ].reportTypeId,
          );
        } else {
          setDataAccessClientsReportTypesActive(0);
        }
      }),
    );
  }, []);

  function getAvailableRoutesForClient() {
    if (dataAccessClientsReportTypesActive === 1) return routesWithMarketTab;
    if (dataAccessClientsReportTypesActive === 2 || dataAccessClientsReportTypesActive === 3)
      return routesWithMarketAndtRiskTabs;
    return routes;
  }

  return (
    <>
      <Header />
      <Navbar
        routes={getAvailableRoutesForClient()}
        {...props}
        personaUser={personaUser}
        personaStatus={personaStatus}
        setPersonaStatus={setPersonaStatus}
      />
      <div
        style={{
          width: '100%',
          background: '#71207c',
        }}
      />
      <div className="tabsNavigator__banner" />
      <div>
        <Container style={{ maxWidth: '1920px', marginTop: '50px' }}>
          <div id="AutocountRouteComponentStyleContainer" className="route__component">
            {renderRoutes(
              Object.values(getAvailableRoutesForClient()),
              LAYOUT,
              getAvailableRoutesForClient().home.path,
            )}
          </div>
        </Container>
      </div>
     
      <Footer />
       {process.env.REACT_APP_COOKIES_POLICY_URL && (
        <CookieConsent
          privacyPolicyURL={process.env.REACT_APP_COOKIES_POLICY_URL}
          cookieName="cookieConsent"
          message="Cookies help us deliver the best experience on our website. By using our webside, you agree the use of cookies. "
        />
      )}
      
    </>
  );
}

export default AutocountLayout;
