import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function FormField({ fieldTitle, fieldInput, dataCy, infoMessage }) {
  return (
    <Grid container item xs={12} wrap="nowrap">
      <Grid item display="flex" alignItems="center">
        <Typography variant="h4" marginBottom={0} data-cy={`${dataCy}-label`}>
          {fieldTitle}
        </Typography>
        {infoMessage && (
          <Tooltip title={infoMessage}>
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
        )}
      </Grid>
      <Grid item xs={12} data-cy={`${dataCy}-wrapper`}>
        {fieldInput}
      </Grid>
    </Grid>
  );
}

FormField.propTypes = {
  fieldTitle: PropTypes.string.isRequired,
  fieldInput: PropTypes.element.isRequired,
};

export default FormField;
